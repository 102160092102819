<template>
    <div class="container">
        <Card title="人物故事" :extra='false'>
            <div class="panel">
                <div class="item" v-for="(item, index) in list" :key="index" @click="openArticleDetail(item)">
                    <img :src="item.face_img" alt="">
                    <div class="title">
                        {{item.title}}
                    </div>
                </div>
            </div>

        </Card>
    </div>
</template>

<script>
import article from '@/mixins/article'
import { fetchList } from '@/api/article'
export default {
    mixins: [article],
    data() {
        return {
            list: [],
        }
    },

    mounted() {
        fetchList({
            p: [{
                category: 'rwgs',
                page: 1,
                rows: 100,
            }]
        }).then(res => {
            if (Array.isArray(res) && res.length > 0) {
                if (res[0].data && Array.isArray( res[0].data.list)) {
                    this.list = res[0].data.list;
                }   
            }
        })
    }
}
</script>

<style lang="less" scoped>
.item {
    position: relative;
    overflow: hidden;
    cursor: pointer;
    img {
        width: 100%;
        transition: all .3s;
        &:hover {
            transform: scale(1.1);
        }
    }
    .title {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, .5);
        color: #fff;
        text-align: center;
        padding: 4px;
        font-size: 12px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
}

</style>
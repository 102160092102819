export default {
    methods: {
        openArticleDetail({ url, code }) {
            if (url) {
                window.open(url)
            } else {
                this.$router.push('/article-detail?code=' + code)
            }
        }
    }
}
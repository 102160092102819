import request from './request'

export function fetchList({ p }) {
    const form = new FormData();
    form.append('p', JSON.stringify(p))
    return request({
        url: '/Article/getList',
        method: 'post',
        data: form,
    })
}

export function search({ p }) {
    const form = new FormData();
    form.append('p', JSON.stringify(p))
    return request({
        url: '/Article/getSearchList',
        method: 'post',
        data: form,
    })
}

export function fetchDetail({ code }) {
    return request({
        url: '/Article/getDetail?code=' + code,
    })
}

export function fetchCategory() {
    return request({
        url: '/Article/getCategoryList',
    })
}